import Iframe from 'react-iframe';

const IframeWrapper = (props) => {
  const {
    url,
    className,
    sandbox,
    width = '100%',
    height = '100%',
    overflow = 'auto',
    allowFullScreen = true
  } = props;

  return (
    <Iframe
      url={url}
      width={width}
      height={height}
      className={className}
      sandbox={sandbox}
      allowFullScreen={allowFullScreen}
      overflow={overflow}
      loading="lazy"
    />
  );
};

export default IframeWrapper;
